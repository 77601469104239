// VPRO color palette
const colors: { [key: string]: number } = {
  // Black/white/grey
  White: 0xffffff,
  Black: 0x000000,
  Greybat: 0x333333,
  Greygrizzly: 0x666666,
  Greyelephant: 0xaaaaaa,
  Greymouse: 0xcccccc,
  Greyjerboa: 0xeeeeee,
  Greywolf: 0xd6d6d6,

  // Colors
  Blue: 0x00aaff,
  Gold: 0xff9900,
  Green: 0x00cc00,
  Lime: 0x00ff00,
  Orange: 0xff5500,
  Pink: 0xff0099,
  Plum: 0xcc00ff,
  Red: 0xff0000,
  Royal: 0x0066ff,
  Turquoise: 0x00cccc,
  Violet: 0x6666ff,
  Yellow: 0xffdd00,

  // 20% darker
  Darkblue: 0x0000ff,
  Darkgold: 0xcc9900,
  Darkgreen: 0x009900,
  Darklime: 0x66cc00,
  Darkorange: 0xff3300,
  Darkpink: 0xcc3366,
  Darkplum: 0x9933cc,
  Darkred: 0xcc0000,
  Darkroyal: 0x0033cc,
  Darkturquoise: 0x009999,
  Darkviolet: 0x330099,
  Darkyellow: 0xffdd00,
  Darkgreywolf: 0xcccccc,
  Darkgreymouse: 0xaaaaaa,
  Darkgreyelephant: 0x666666,
  Darkgreygrizzly: 0x333333,
  Darkgreybat: 0x000000,

  // 20% lighter
  Lightblue: 0x00ccff,
  Lightgold: 0xffcc33,
  Lightgreen: 0x33ff99,
  Lightlime: 0x99ff00,
  Lightorange: 0xff9933,
  Lightpink: 0xff99cc,
  Lightplum: 0xcc99ff,
  Lightred: 0xff9999,
  Lightroyal: 0x6699ff,
  Lightturquoise: 0x00ffff,
  Lightviolet: 0x9999ff,
  Lightyellow: 0xffff66,
};

export default colors;
