import Env from './Env';

// Events
export const EVENT_NAVIGATE_REPLACE = 'EVENT_NAVIGATE_REPLACE';
export const EVENT_NAVIGATE_PATCH = 'EVENT_NAVIGATE_PATCH';
export const EVENT_NOTIFICATION_SHOW = 'EVENT_NOTIFICATION_SHOW';
export const EVENT_VIEWPORT_TOUCH_EMPTY = 'EVENT_VIEWPORT_TOUCH_EMPTY';
export const EVENT_VIEWPORT_DOUBLE_CLICK = 'EVENT_VIEWPORT_DOUBLE_CLICK';
export const EVENT_HIGHLIGHT_ENTITY = 'EVENT_HIGHLIGHT_ENTITY';
export const EVENT_HIGHLIGHT_EPISODE = 'EVENT_HIGHLIGHT_EPISODE';
export const EVENT_VIEWPORT_ZOOM = 'EVENT_VIEWPORT_ZOOM';
export const EVENT_VIEWPORT_MIDDLE_MOUSE_CLICK =
  'EVENT_VIEWPORT_MIDDLE_MOUSE_CLICK';
export const EVENT_VIEWPORT_TOUCH_EPISODE_NODE =
  'EVENT_VIEWPORT_TOUCH_EPISODE_NODE';
export const EVENT_PLAYER_SET_TIME = 'EVENT_PLAYER_SET_TIME';
export const EVENT_PLAYER_TIME_UPDATE = 'EVENT_PLAYER_TIME_UPDATE';

// Local/Session storage keys
export const STORE_MODE = 'STORE_MODE';
export const STORE_LAYOUT = 'STORE_LAYOUT';
export const STORE_INFO_TAB = 'STORE_INFO_TAB';
export const STORE_HINT_NAVIGATION = 'STORE_HINT_NAVIGATION';

// Visual
export const FONT_NAME = 'SimplisticSans-Bold';
export const TILE_PLACEHOLDER_IMAGE = '/images/textures/tegenlicht_512.webp';
export const TILE_ARROW = '/images/textures/tile_arrow_256.webp';
export const TILE_SIZE = 256;
export const NODE_SPREAD = 2000;
export const RANDOM_SEED = 20211015;
export const MAX_CLICK_TIME = 350;
export const MAX_CLICK_DISTANCE = 20;
export const MAX_MIDDLE_MOUSE_CLICK_TIME = 500;

// Spritesheets
export const TILES_PER_SPRITESHEET_128 = 256; // 2048x2048 spritesheet, 128x128 frames

// Dimensions
export const HEADER_HEIGHT = 60;
export const MOBILE_HEADER_HEIGHT = 50;

// Device dimensions
export const IPAD_SCREEN_WIDTH = 1180;
export const WINDOW_WIDTH_FULL_SCREEN_EPISODE_VIEW = 1180;

// Debug
export const DISABLE_PREVIEW_VIDEO =
  window.location.hash?.indexOf('disable-preview-video') > -1;
export const FORCE_HDPI =
  Env.forceHDPI || window.location.hash?.indexOf('hdpi') > -1;
