import { useEffect, useRef, useState } from 'react';

import debounce from 'debounce';
import { FORCE_HDPI, IPAD_SCREEN_WIDTH } from 'config/constants';

export const isMobile = () =>
  window.innerWidth <= 810 &&
  ('ontouchstart' in window || navigator.maxTouchPoints > 0);
export const isTablet = () =>
  window.innerWidth <= IPAD_SCREEN_WIDTH &&
  ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const getPixelDeviceRatio = () =>
  isMobile() && !FORCE_HDPI ? 1 : window.devicePixelRatio;

type DeviceState = { IS_MOBILE: boolean; IS_TABLET: boolean };

type Hook = () => DeviceState;

const initState = {
  IS_MOBILE: isMobile(),
  IS_TABLET: isTablet(),
};

// useDevice recognizes mobile/tablet devices
const useDevice: Hook = () => {
  const [deviceState, setDeviceState] = useState(initState);
  const lastState = useRef<DeviceState>(initState);

  useEffect(() => {
    const updateDimensions = debounce(() => {
      const newState = { IS_MOBILE: isMobile(), IS_TABLET: isTablet() };

      // Only update on value change
      if (
        lastState.current.IS_MOBILE !== newState.IS_MOBILE ||
        lastState.current.IS_TABLET !== newState.IS_TABLET
      ) {
        setDeviceState(newState);
        lastState.current = newState;
      }
    }, 200);

    window.addEventListener('resize', updateDimensions);
    window.addEventListener('orientationchange', updateDimensions);
    return () => {
      updateDimensions.clear();
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('orientationchange', updateDimensions);
    };
  }, []);

  return deviceState;
};

export default useDevice;
